import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Input,
} from "@chakra-ui/react";
import styles from "./../style.module.css";
import crossImg from "../../../../assets/crossImg.svg";

import { message } from "antd";
import { useDispatch } from "react-redux";
import {
  fetchExternalAdData,
  updateExternalAdData,
} from "../../../../Redux/Advertisement/External/ExternalAdReducer";
import {
  fetchPartnerAdData,
  updatePartnerAdData,
} from "../../../../Redux/Advertisement/Partner/PartnerAdReducer";

const initialState = {
  reason: "",
};

const ReasonModel = ({ el, type, page, partnerAd }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [form, setForm] = useState(initialState);
  const dispatch = useDispatch();
  console.log("type", type);
  console.log("page", page);
  console.log("el", el);
  const handleYes = () => {
    const data = {
      adId: el._id,
      partnerId: el.partnerId._id,
      status: "Rejected",
      reason: form.reason,
    };
    console.log("data", data);

    if (partnerAd) {
      dispatch(updatePartnerAdData(data)).then(() => {
        dispatch(
          fetchPartnerAdData(type, "", page, "", "", "", "", "", "")
        ).then(() => {
          onClose();
          message.error("Ad has been rejected");
        });
      });
    } else {
      dispatch(updateExternalAdData(data)).then(() => {
        dispatch(
          fetchExternalAdData(type, "", page, "", "", "", "", "", "")
        ).then(() => {
          onClose();
          message.error("Ad has been rejected");
        });
      });
    }
  };

  return (
    <>
      <p onClick={onOpen} className={styles.blackBtn31}>
        Reject
      </p>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody></ModalBody>
          <div className={styles.deleteModeldiv}>
            <img src={crossImg} alt="" />

            <p>
              Write a reason.
              <br />
              Why the Ad got rejected?
            </p>
            <Input
              placeholder="Write Rejecte Reason (max. 50 characters)"
              value={form.reason}
              onChange={(e) => setForm({ ...form, reason: e.target.value })}
            />
          </div>

          <ModalFooter>
            <div className={styles.modelFooter}>
              <Button variant="solid" color="black" mr={3} onClick={handleYes}>
                Yes
              </Button>

              <Button variant="outline" color="black" onClick={onClose}>
                No
              </Button>
            </div>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ReasonModel;
