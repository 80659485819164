import React, { forwardRef, useState } from "react";
import styles from "./style.module.css";
import { Button, Input, Select, CloseButton, Stack } from "@chakra-ui/react";
// import { createCurrentVoucherData, fetchCurrentVoucherData } from '../../../Redux/Voucher/CurrentVoucherReducer'
import { useDispatch } from "react-redux";
import { message } from "antd";
import pushIcon from "../../../../assets/icons/pushIcon.svg";
import {
  createUserNotificationData,
  fetchUserNotificationData,
} from "../../../../Redux/User/UserNotificationReducer";
import ReactDatePicker from "react-datepicker";
import { adjustedDate } from "../../../../utils/common";

const CreateNotification = ({ type, page, setCreate }) => {
  const dispatch = useDispatch();
  const initialState = {
    name: "Admin",
    title: "",
    body: "",
    status: "Upcoming",
    userType: "USER",
    // sendTo: "All",
    type: "",
    cities: [],
    releaseDate: "",
    releaseTime: "",
  };
  const [form, setForm] = useState(initialState);
  const currentDate = new Date().toISOString().split("T")[0];
  const [selectedDate, setSelectedDate] = useState(null);

  const addData = () => {
    if (!form.title) {
      message.error("Enter title");
    } else if (!form.body) {
      message.error("Body cann't be empty");
    } else if (!form.cities) {
      message.error("Select cities");
    } else if (!form.releaseDate) {
      message.error("Select releaseDate");
    } else if (!form.releaseTime) {
      message.error("Select releaseTime");
    } else {
      dispatch(createUserNotificationData(form))
        .then((data) => {
          console.log("Success:", data); // Log success data
          dispatch(fetchUserNotificationData(type, "", page));
          setCreate(false);
          message.success("Notification created successfully");
        })
        .catch((error) => {
          console.log("Error:", error); // Log error message
          message.error(`Error creating notification: ${error}`);
        });
    }
  };

  const addCity = () => {
    if (form.city && !form.cities.includes(form.city)) {
      setForm((prevForm) => ({
        ...prevForm,
        cities: [...prevForm.cities, prevForm.city],
        city: "",
      }));
    }
  };

  const removeCity = (removedCity) => {
    setForm({
      ...form,
      cities: form.cities.filter((city) => city !== removedCity),
    });
  };
  const getMinDate = () => {
    const date = new Date();
    date.setDate(date.getDate() + 0);
    return date;
  };

  const minDate = getMinDate();

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <Input
      value={value}
      onClick={onClick}
      placeholder="YYYY-MM-DD"
      style={{ cursor: "pointer" }}
      ref={ref}
    />
  ));
  const handleDateChange = (date) => {
    setSelectedDate(date);
    console.log("date", date);
    const originDate = adjustedDate(date);
    console.log(
      "date.toISOString().split[0]",
      originDate.toISOString().split("T")[0]
    );

    setForm({ ...form, releaseDate: originDate.toISOString().split("T")[0] });
  };
  return (
    <div className={styles.MainContainer}>
      <div className={styles.container1}>
        <div className={styles.breadcrumb}>
          <button
            onClick={() => setCreate(false)}
            className="cursor-pointer text-gray-500 hover:text-gray-800"
          >
            All Notifications
          </button>{" "}
          <p className="mx-2">&gt; </p>
          <b>Create New Notification</b>
        </div>

        <div className={styles.header}>
          <h1>Create New Notification</h1>
          <div className={styles.btn}>
            <Button
              colorScheme="black"
              variant="outline"
              w="127px"
              onClick={() => setCreate(false)}
            >
              Cancel
            </Button>
            <Button
              bg="black"
              color="#fff"
              variant="solid"
              w="127px"
              onClick={addData}
            >
              Save
            </Button>
          </div>
        </div>
        <div className={styles.grid}>
          <div className={styles.resetPassword}>
            <p>Title*</p>
            <Input
              placeholder="Title (max. 50 characters)"
              value={form.title}
              onChange={(e) => setForm({ ...form, title: e.target.value })}
            />
            <p>Body*</p>
            <Input
              placeholder="Add Body (max. 150 characters)"
              value={form.body}
              onChange={(e) => setForm({ ...form, body: e.target.value })}
            />
            <p>Select user type</p>
            <Select
              placeholder="Choose User"
              isReadOnly
              onChange={(e) => setForm({ ...form, type: e.target.value })}
            >
              <option value="ALL_USER">All</option>
              <option value="STANDARD">Standard</option>
              <option value="PREMIUM">Premium</option>
            </Select>
            <p>Only Received by users in the following city:</p>
            <div className={styles.citydiv}>
              <div className={styles.scrollstack}>
                <Stack direction="row" spacing={2} align="center">
                  {form.cities.map((city) => (
                    <div key={city} className={styles.selectedCity}>
                      <p>{city}</p>
                      <CloseButton onClick={() => removeCity(city)} />
                    </div>
                  ))}
                </Stack>
              </div>
              <input
                placeholder="City"
                type="text"
                value={form.city}
                onChange={(e) => setForm({ ...form, city: e.target.value })}
                onKeyDown={(e) => e.key === "Enter" && addCity()}
                onBlur={addCity}
              />
            </div>
            <div className={styles.dateDiv}>
              <p>Release on:</p>
              <ReactDatePicker
                wrapperClassName={styles.datepicker}
                className={styles.date_picker}
                selected={selectedDate}
                onChange={handleDateChange}
                customInput={<CustomInput />}
                minDate={minDate}
                dateFormat="yyyy-MM-dd"
                popperModifiers={[
                  {
                    name: "arrow",
                    options: {
                      padding: ({ popper, reference, placement }) => ({
                        right: Math.min(popper.width, reference.width) - 24,
                      }),
                    },
                  },
                ]}
              />
              <Input
                type="time"
                onChange={(e) =>
                  setForm({ ...form, releaseTime: e.target.value })
                }
              />
            </div>
          </div>
          <div className={styles.container2}>
            <div className={styles.preview}>
              <p>Preview</p>
              <div className={styles.previewInner}>
                <span className={styles.spanIcon}>
                  <img src={pushIcon} alt="" />
                </span>
                <div>
                  <h2>{form.title}</h2>
                  <p>{form.body}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateNotification;
