import React, { forwardRef, useState } from "react";
import styles from "./style.module.css";
import { Button, Input, Select } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import {
  createCurrentVoucherData,
  fetchCurrentVoucherData,
} from "../../../../Redux/Voucher/CurrentVoucherReducer";
import { useDispatch } from "react-redux";
import { message } from "antd";
import ReactDatePicker from "react-datepicker";

const initialState = {
  name: "",
  code: "",
  percentage: "",
  startDate: "",
  endDate: "",
  userType: "USER",
};

const CreatePromotion = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [form, setForm] = useState(initialState);
  const addData = () => {
    if (!form.name) {
      message.error("Enter Promotion Name");
    } else {
      if (!form.code) {
        message.error("Enter Code");
      } else {
        if (!form.percentage) {
          message.error("Select Percentage");
        } else {
          if (!form.startDate) {
            message.error("Select startDate");
          } else {
            if (!form.endDate) {
              message.error("Select endDate");
            } else {
              if (new Date(form.endDate) < new Date(form.startDate)) {
                message.error("End Date Cannot be greater then start date");
              } else {
                console.log("form", form);
                dispatch(createCurrentVoucherData(form)).then(() => {
                  dispatch(fetchCurrentVoucherData("Current")).then(() => {
                    navigate("/Promotions");
                    message.success("Promotion created successfully");
                  });
                });
              }
            }
          }
        }
      }
    }
  };

  const getMinDate = () => {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    return date;
  };

  const minDate = getMinDate();
  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <Input
      value={value}
      onClick={onClick}
      placeholder="YYYY-MM-DD"
      style={{ cursor: "pointer" }}
      ref={ref}
    />
  ));
  const handleStartDateChange = (date) => {
    const adjustedDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    );
    setSelectedStartDate(date);
    console.log("date", date);
    console.log("adjustedDate", adjustedDate);
    setForm({
      ...form,
      startDate: adjustedDate.toISOString().split("T")[0],
    });
  };

  const handleEndDateChange = (date) => {
    const adjustedDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    );
    setSelectedEndDate(date);
    console.log("date", date);
    console.log("adjustedDate", adjustedDate);
    setForm({
      ...form,
      endDate: adjustedDate.toISOString().split("T")[0],
    });
  };

  return (
    <div className={styles.MainContainer}>
      <div className={styles.container1}>
        <div className={styles.breadcrumb}>
          <button
            onClick={() => navigate("/Promotions")}
            className="cursor-pointer text-gray-500 hover:text-gray-800"
          >
            All Promotion
          </button>{" "}
          <p className="mx-2">&gt; </p>
          <b>Create New Promotion</b>
        </div>

        <div className={styles.header}>
          <h1>Create New Promotion</h1>
          <div className={styles.btn}>
            <Button
              colorScheme="black"
              variant="outline"
              w="127px"
              onClick={() => navigate("/Promotions")}
            >
              Cancel
            </Button>
            <Button
              bg="black"
              color="#fff"
              variant="solid"
              w="218px"
              onClick={addData}
            >
              Generate Code
            </Button>
          </div>
        </div>

        <div className={styles.resetPassword}>
          <p>Promotion Name</p>
          <Input
            placeholder="Enter Promotion Name"
            value={form.name}
            onChange={(e) => setForm({ ...form, name: e.target.value })}
          />
          <p>Voucher Code</p>
          <Input
            placeholder="Enter Voucher Code"
            value={form.code}
            onChange={(e) => setForm({ ...form, code: e.target.value })}
          />
          <p>Promotion Percentage</p>
          <Select
            placeholder="Choose Percentage"
            value={form.percentage}
            onChange={(e) => setForm({ ...form, percentage: e.target.value })}
          >
            <option value="25% off">25% off</option>
            <option value="50% off">50% off</option>
            <option value="75% off">75% off</option>
            <option value="Buy 1 Get 1 free">Buy 1 Get 1 free</option>
            <option value="1 month free">1 month free</option>
            <option value="3 months free">3 months free</option>
            <option value="6 months free">6 months free</option>
            <option value="12 months free">12 months free</option>
          </Select>
          <p>Start Date</p>
          <ReactDatePicker
            wrapperClassName={styles.datepicker}
            className={styles.date_picker}
            selected={selectedStartDate}
            onChange={handleStartDateChange}
            customInput={<CustomInput />}
            minDate={minDate}
            dateFormat="yyyy-MM-dd"
            popperModifiers={[
              {
                name: "arrow",
                options: {
                  padding: ({ popper, reference, placement }) => ({
                    right: Math.min(popper.width, reference.width) - 24,
                  }),
                },
              },
            ]}
          />
          <p>End Date</p>
          <ReactDatePicker
            wrapperClassName={styles.datepicker}
            className={styles.date_picker}
            selected={selectedEndDate}
            onChange={handleEndDateChange}
            customInput={<CustomInput />}
            minDate={form.startDate ? new Date(selectedStartDate) : null}
            dateFormat="yyyy-MM-dd"
            popperModifiers={[
              {
                name: "arrow",
                options: {
                  padding: ({ popper, reference, placement }) => ({
                    right: Math.min(popper.width, reference.width) - 24,
                  }),
                },
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default CreatePromotion;
